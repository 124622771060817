<template>
    <div>
        <h1 class="title">职位详情</h1>
        <div class="infoBody">
            <el-form ref="form" :model="data" label-width="80px" v-loading="loading">
                <el-form-item label="职位名称">            
                    <span class="content">{{data.job_name}}</span>
                </el-form-item>
                <el-form-item label="职位类型">
                    <span class="content">{{data.allocation}}</span>
                </el-form-item>
                <el-form-item label="薪资待遇">
                    <span class="content" v-if="data.min_salary_range ==0 || data.max_salary_range ==0">面议</span>
                    <span class="content" v-else>{{ data.min_salary_range }}-{{data.max_salary_range }}k</span>           
                </el-form-item>
                <el-form-item label="职位福利">
                    <span class="but" v-for="(item, index) in welfare" :key="index">{{item}}</span>           
                </el-form-item>
                <el-form-item label="招聘人数">
                    <span class="content">{{data.job_num}}人</span>
                </el-form-item>
                <el-form-item label="工作地址">
                    <span class="content">{{data.place_of_work}}人</span>
                </el-form-item>
                <el-form-item label="职位描述">           
                    <div class="text">{{data.job_description}}</div>
                </el-form-item>
                <el-form-item label="BOSS信息">           
                    <ul class="userInfo">
                        <li>
                            <span v-if="data.user_nickname" >{{data.user_nickname}}</span>
                            <span v-else>十二方用户</span>
                            </li>
                        <li class="imageInfo">
                            <img v-if="data.user_avatar" :src="data.user_avatar" alt="">
                            <img v-else :src="require('@/assets/images/userAvatar.png')" alt="">
                        </li>
                        <li>
                            <img v-if="data.user_auth_state == 3" :src="require('@/assets/images/auth1.png')" class="auth">
                            <img v-else :src="require('@/assets/images/auth2.png')" class="auth">

                            <img v-if="data.cert_company == 2" :src="require('@/assets/images/rz02.png')" class="enterprise">
                            <img v-else :src="require('@/assets/images/rz01.png')" class="enterprise">                            
                        </li>
                        <li>
                            <el-button type="primary" @click="getPone(data.id)">拨打电话</el-button>                           
                        </li>
                    </ul>               
                </el-form-item>
            </el-form>
        </div>
    </div>
    
</template>

<script>
import {getCollJobInfoApi} from '@/api/collection'
import {getUserPhoneApi} from '@/api/user'

    export default{
       data(){
            return{
                id: '',
                loading: false,
                data: {
                    job_name: '',
                    allocation: '',
                    job_num: '',
                    job_description: '',
                    place_of_work: '', 
                    user_nickname: '',
                    user_avatar: '',
                    cert_company: '',
                    user_auth_state: ''                   
                },
                welfare: []
            }
        },

        created(){
            this.id = this.$route.query.id
            this.getCollJobInfo()
        },

        methods: {
            async getCollJobInfo(){
                this.loading = true
                const {
                    data: res
                } = await getCollJobInfoApi(this.id)
                if (res.status != 10000) {
                    return this.$message.error(res.message)
                }
                this.data = res.result
                this.welfare = res.result.welfare
                this.loading = false
            },

            async getPone(id){               
                const {
                    data: res
                } = await getUserPhoneApi(id)
                if (res.status != 10000) {
                    return this.$message.error(res.message)
                }
                this.phone = res.result
                this.$MessageBox.alert('电话: '+this.phone.user_phone, '联系方式', {
                confirmButtonText: '确定',               
                }).then(() =>{}).catch(() =>{});               
            }
            
        }
    }

    
</script>

<style lang="less" scoped>
    .infoBody{
        padding-top: 20px;
    }
    .title{
        text-align: center;
        font-weight: normal;
        line-height: 40px;
        border-bottom: 1px solid #e4e7ed;
        font-size: 20px;
    }
    .content{
        display: block;
        padding: 0 15px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
    }
    .text{
        padding: 0 15px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        min-height: 200px;
        white-space: pre-wrap;
        line-height: 1.9;
    }
    .but{
        font-size: 12px;
        display: inline-block;
        padding: 5px;
        text-align: center;
        background-color: #e5f4ff;
        color: #0092ff;
        border-radius: 6px;
        margin-right: 5px;
        height: 20px;
        line-height: 20px;
    }
    .imageInfo img{
        width: 30px;
        display: block;
    }
    .auth{
        width: 27px;
        display: inline-block;
        vertical-align: middle;
    }
    .enterprise{
        width: 30px;
        display: inline-block;
        vertical-align: middle;
    }
    .userInfo li{
        min-height: 40px;
    }
    .el-message-box__message p{
        letter-spacing: 1px;
    }
    
.el-button--primary{
    margin-top: 20px;
    background-color: #409EFF;
    border-color: #409EFF;
    color: #FFF;
}
.el-button--primary:hover{
    background: #66b1ff;
    border-color: #66b1ff;
    color: #fff;
}
.el-button--default:hover{
    background: #66b1ff;
    border-color: #66b1ff;
    color: #fff;
}
</style>
