import request from '@/libs/request'

//收藏职位列表
export function getCollectionApi(page, limit) {
    return request({
        url: `/collection/collList?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

//收藏职位详情
export function getCollJobInfoApi(id) {
    return request({
        url: `company/getInfo/${id}`,
        method: 'get',
    })
}

//移除收藏职位
export function delJobCollApi(id) {
    return request({
        url: `collection/delColl/${id}`,
        method: 'get',
    })
}
